'use client' // Error components must be Client Components

import { useEffect } from 'react'

import { ErrorPage } from '@seeurcargo/shared'

type NextErrorPageProps = {
	error: Error & {
		digest?: string
	}
}

const NextErrorPage = ({ error }: NextErrorPageProps) => {
	useEffect(() => {
		// Log the error to an error reporting service
		console.warn(error)
	}, [error])

	return (
		<ErrorPage
			onSubmit={window.location.reload}
			img="/error/500.svg"
			title="Что-то пошло не так!"
			buttonText="Перезагрузить"
			description="Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время 🥲"
		/>
	)
}

export default NextErrorPage
